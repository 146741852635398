
import { ResponsiveLine } from '@nivo/line';

export const LineChart = props => (

    <ResponsiveLine
        data={props.data}
        enableSlices="x"
        margin={{ top: 10, right: 10, bottom: 30, left: 50 }}
        xScale={{
                  type: 'time',
                  format: '%Y-%m-%d %H:%M',
                  useUTC: true,
                  precision: 'minute',
                }}
        xFormat="time:%b %d %H:%M"

        yScale={{
                  type: 'linear',
                  min: 'auto',
                  max: 'auto',
                  stacked: false,

                }}
        yFormat=">-,.2f"
        curve="cardinal"
        axisTop={null}
        axisRight={null}
        axisLeft={{
          orient: 'left',
          tickSize: 0,
          tickPadding: 15,
          format: '>-,.2f',
          tickValues: 4,

        }}
        axisBottom={{
            format: '%b %d',
            tickValues: 'every day',
            tickSize: 0,
            tickValues: 2,
        }}

        colors={props.colors}
        borderColor="#000000"
        lineWidth={4}
        enablePoints={false}
        enableGridX={false}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        enableArea={true}
        areaOpacity={0.2}
        useMesh={true}
        animate={false}
        legends={[]}
        theme={{
          "fontSize": 14,
          "textColor": "#797B80",

          "grid": {
            "line": {
                "stroke": "#dddddd",
                "strokeWidth": 0.8
              }
          }
        }}
        sliceTooltip={({ slice }) => {
              return (
                  <div
                      style={{
                          background: 'white',
                          padding: '10px 12px',
                          border: '1px solid #ccc',
                      }}
                  >
                      {slice.points.map(point => (
                          <div
                              key={point.id}
                              style={{
                                  color: point.serieColor,
                                  padding: '3px 0',
                              }}
                          >
                              {point.data.xFormatted}<br/>
                              <strong>{point.data.yFormatted}</strong>
                          </div>
                      ))}
                  </div>
              )
          }}
    />
  );
