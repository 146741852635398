import moment from 'moment'

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
};

export function handleGetDefault (array) {
  const filteredArray = array.filter(el => el.default === true);
  if (filteredArray.length > 0) {

    return filteredArray[0];
  }
  return false;
};

export function handleFormatStates (states) {
  const keys = Object.keys(states);
  return keys.map(k => {
    return {
      key: k,
      text: states[k],
      value: k
    };
  });
};

export function transfromData (data) {

  const newData = [
    {
    "id":"high",
    "data":data.map(function(element){
        return {"x":element.t,"y":element.v}
    })
    },

  ]
  return newData


}

export function transfromFinnhubb (data) {
  const closingPrices = data.c
  const closingDates = data.t

  const newData = [
    {
    "id":"spy",
    "data":closingPrices.map(function(element,i){
        return {"x":moment.unix(closingDates[i]).format("YYYY-MM-DD HH:MM"),"y":element}
    })
    },

  ]
  return newData


}
