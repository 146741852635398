import { STOCK_START, STOCK_SUCCESS, STOCK_FAIL } from "./actionTypes";

import axios from "axios";
import { endpoint, stockURL} from "../../constants";

export const stockStart = () => {
  return {
    type: STOCK_START
  };
};

export const stockSuccess = data => {
  return {
    type: STOCK_SUCCESS,
    data
  };

};

export const stockFail = error => {
  return {
    type: STOCK_FAIL,
    error: error
  };
};


export const fetchStock = (symbol,begin,end) => {

  return dispatch => {
    dispatch(stockStart());
    axios
      .get(stockURL(symbol,begin,end))
      .then(res => {
        
        dispatch(stockSuccess(res.data));
      })
      .catch(err => {
        console.log(err);
        dispatch(stockFail(err));
      });
  };
};
