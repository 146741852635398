
import './App.css';
import {FrontPage} from "./pages/frontpage/FrontPage";


function App() {
  return (
    <div className="App">
      <FrontPage/>
    </div>
  );
}

export default App;
