const server = `https://api.joelsalvo.com`;
const localhost = `http://127.0.0.1:8000`;

export const finnhub = "https://finnhub.io/api/v1";
const finnhubToken = "c3o8caaad3ia07uend2g"

export const noaa = "https://api.tidesandcurrents.noaa.gov/api/prod";

export const stockURL = (symbol,begin,end) => `${finnhub}/stock/candle?symbol=${symbol}&resolution=D&from=${begin}&to=${end}&token=${finnhubToken}`;
export const tideURL = (begin,end,station,product) => `${noaa}/datagetter?\
begin_date=${begin}&\
end_date=${end}&\
station=${station}&\
product=${product}&\
datum=STND&\
units=english&\
time_zone=lst&\
interval=hilo&\
application=DataAPI&\
format=json`;
