import { TIDE_START, TIDE_SUCCESS, TIDE_FAIL } from "../actions/actionTypes";
import { updateObject,transfromData } from "../utility";

const initialState = {
  data: [],
  error: null,
  loading: false,
};

const tideStart = (state, action) => {
  return updateObject(state, {
    loading: true
  });
};

const tideSuccess = (state, action) => {

  return updateObject(state, {
    data: transfromData(action.data.predictions),
    loading: false
  });
};

const tideFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TIDE_START:
      return tideStart(state, action);
    case TIDE_SUCCESS:
      return tideSuccess(state, action);
    case TIDE_FAIL:
      return tideFail(state, action);
    default:
      return state;
  }
};

export default reducer;
