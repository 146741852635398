import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { useDispatch, useSelector} from 'react-redux'
import {
  Segment,
  Grid,
  Container,
  Header,
  Icon,
  Button,
  Divider,
  Menu
  } from "semantic-ui-react";
import './FrontPage.css'
import background from "../../2022-06-26 001.JPG";
import { LineChart } from "../../charts/line/line";
import { StockChart} from "../../charts/stocks/stocks";

import { fetchTide } from "../../store/actions/tide";
import { fetchStock } from "../../store/actions/stocks";

import axios from "axios";

import moment from 'moment'


const tideTEST = {
  "begin_date": moment().format('YYYYMMDD'),
  "end_date": moment().add(2, 'days').format('YYYYMMDD'),
  "station_id":'8720587',
  "product":'predictions'
}

function createTideURL (props) {
const URLtext =`https://api.tidesandcurrents.noaa.gov/api/prod/datagetter?\
begin_date=${props.begin_date}&\
end_date=${props.end_date}&\
station=${props.station_id}&\
product=${props.product}&\
datum=STND&\
units=english&\
time_zone=lst&\
interval=hilo&\
application=DataAPI&\
format=json`
  return URLtext

}


const baseURL = createTideURL(tideTEST);

const stockDates = {
  "symbol":"SPY",
  "begin": moment().subtract(60, 'days').unix(),
  "end": moment().unix(),
}
const stockURL = `https://finnhub.io/api/v1/stock/candle?symbol=SPY&resolution=D&from=${stockDates.begin}&to=${stockDates.end}&token=c3o8caaad3ia07uend2g`

export function FrontPage (props) {
  const tide = useSelector((state) => state.tide )
  const stocks = useSelector((state) => state.stock )
  const [chartData, setChartData] = useState({"title":null,"data":[]});
  const [activeItem, setActiveItem] = useState("tides");
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchTide(tideTEST.begin_date,
      tideTEST.end_date,
      tideTEST.station_id,
      tideTEST.product)
    )
    dispatch(fetchStock(stockDates.symbol, stockDates.begin, stockDates.end)
    )

  }, []);

  useEffect(() => {
    if (activeItem === "tides") {
      if (tide.loading){
        setLoading(true)
      }else{
        setChartData({"title":"St Augustine Beach, FL",
                      "data":tide.data,
                      "colors":[ '#28BBBA' ]
                    })
        setLoading(false)
      };
    }
    else if (activeItem === "markets") {
      if (tide.loading){
        setLoading(true)
      }else{
        setChartData({"title":stockDates.symbol,
                      "data":stocks.data,
                      "colors":[ '#DD1552' ]
                    })
        setLoading(false)
      }
    }

  }, [activeItem,tide,stocks]);

  return(
    <div className="whole-page">
    <Segment basic style={{padding: '2em 1em'}}>
      <Grid stackable container style={{margin: '2em 0em'}}>
        <Grid.Row>
        <Grid.Column align="left">
        <p className="header-text">Joel Salvo</p>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
        <Grid.Column align="left">
        <a className="custom-link" href='https://twitter.com/JoeySalvs' target="_blank">
          <Icon name='twitter' size='large' />
        </a>
        <a className="custom-link" href='https://github.com/joeysalvs' target="_blank">
        <Icon name='github' size='large' />
        </a>
        </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
    <Segment basic>
        <Grid stackable container style={{margin: '2em 0em'}}>
        <Grid.Row columns={2} style={{"padding":"0em"}}>
        <Grid.Column align="center">
        <Menu text style={{margin:"0em -0.5em"}}>
        <Menu.Item
          active={activeItem === 'tides'}
          onClick={()=>setActiveItem('tides')}
        >
        <p className="segment-text">Tides</p>
        </Menu.Item>
        <Menu.Item
          active={activeItem === 'markets'}
          onClick={()=>setActiveItem('markets')}
        >
        <p className="segment-text">Markets</p>
        </Menu.Item>
        </Menu>

        </Grid.Column>

        </Grid.Row>
        <Divider style={{margin:"0em 1em 1em 1em"}}/>
        <Grid.Row>
        <Grid.Column align="left">
        {loading ? (
          <div>
          <p>Loading</p>
          </div>

        ):(
        <React.Fragment>
        <p className="chart-title">{chartData.title}</p>
        <div style={{height:"300px"}}>
        <LineChart data={chartData.data} colors={chartData.colors}/>
        </div>
        </React.Fragment>
        )}
         </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
    </div>
  )

}
