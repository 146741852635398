import { TIDE_START, TIDE_SUCCESS, TIDE_FAIL } from "./actionTypes";

import axios from "axios";
import { endpoint, tideURL} from "../../constants";

export const tideStart = () => {
  return {
    type: TIDE_START
  };
};

export const tideSuccess = data => {
  return {
    type: TIDE_SUCCESS,
    data
  };

};

export const tideFail = error => {
  return {
    type: TIDE_FAIL,
    error: error
  };
};


export const fetchTide = (begin,end,station,product) => {

  return dispatch => {
    dispatch(tideStart());
    axios
      .get(tideURL(begin,end,station,product))
      .then(res => {
        dispatch(tideSuccess(res.data));
      })
      .catch(err => {
        console.log(err);
        dispatch(tideFail(err));
      });
  };
};
