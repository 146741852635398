import { STOCK_START, STOCK_SUCCESS, STOCK_FAIL } from "../actions/actionTypes";
import { updateObject,transfromFinnhubb } from "../utility";

const initialState = {
  data: [],
  error: null,
  loading: false,
};

const stockStart = (state, action) => {
  return updateObject(state, {
    loading: true
  });
};

const stockSuccess = (state, action) => {

  return updateObject(state, {
    data: transfromFinnhubb(action.data),
    loading: false
  });
};

const stockFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case STOCK_START:
      return stockStart(state, action);
    case STOCK_SUCCESS:
      return stockSuccess(state, action);
    case STOCK_FAIL:
      return stockFail(state, action);
    default:
      return state;
  }
};

export default reducer;
